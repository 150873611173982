module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Joshua's Cheatsheets and Miscellaneous Notes","short_name":"Joshua's Cheatsheets","description":"Joshua Tzucker's collection of cheat sheets, miscellaneous notes, and more!","lang":"en","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/iconfinder_30_icons_2191560.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aa856616f6a69c11bee19cc45dadc8b0"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"icon":"<?xml version=\"1.0\" ?><svg id=\"Outlined\" viewBox=\"0 0 32 32\" xmlns=\"http://www.w3.org/2000/svg\"><title/><g id=\"Fill\"><path fill=\"currentColor\" d=\"M29.35,6.88,25.11,2.63a3,3,0,0,0-4.23,0L14.64,8.81a3,3,0,0,0,0,4.25l1.44,1.45-1.54,1.54-1.42-1.42a3,3,0,0,0-4.24,0L2.65,20.8a3,3,0,0,0,0,4.26L6.88,29.3A3,3,0,0,0,9,30.17a3,3,0,0,0,2.11-.86l6.23-6.19A3,3,0,0,0,18.24,21a3,3,0,0,0-.88-2.13L16,17.46l1.54-1.54,1.39,1.38a3,3,0,0,0,4.23,0l6.23-6.18A2.94,2.94,0,0,0,30.23,9,3,3,0,0,0,29.35,6.88ZM15.94,20.29a1,1,0,0,1,.3.71,1,1,0,0,1-.3.7L9.71,27.89a1,1,0,0,1-1.41,0L4.06,23.64a1,1,0,0,1-.3-.71,1,1,0,0,1,.3-.71L10.29,16a1,1,0,0,1,1.41,0l1.42,1.42-2.83,2.83,1.42,1.42,2.83-2.83Zm12-10.58L21.7,15.89a1,1,0,0,1-1.41,0l-1.38-1.38,2.8-2.8-1.42-1.42-2.8,2.8-1.44-1.44a1,1,0,0,1,0-1.42l6.23-6.18A1,1,0,0,1,23,3.76a1,1,0,0,1,.71.29l4.24,4.24a1,1,0,0,1,0,1.42Z\"/></g></svg>","offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-XN17M9WK29","head":false,"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
